@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.disabled {
  pointer-events: none;
}
.hide{
  display: none;
}
.navbar a {
  font-weight: 400;
}

body div.App {
  line-height: 1.5;
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fake-link {
  color: #3174d8;
  cursor: pointer;
}

.fake-link-inactive {
  color: #3174d8;
  cursor: default;
}

.main-container.hide-app>.container, .main-container.hide-app>.row {
  display: none;
}

.App nav .fas{
  margin-bottom: 0px;
}

.App nav i.fas {
  font-size: 1.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
