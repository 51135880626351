.left_hand_menu{
    z-index:1;
    color: hsl(0, 0%, 100%); 
    height: 100vh;
}
.body_content{
    display: flex;
}
.cmp-left-navigation-toggle div{
    background-color: #4D4D4D;
}
.cmp-left-navigation--is-open .cmp-left-navigation-container {
    background-color: #4D4D4D;
}
.cmp-left-navigation--is-open .cmp-left-navigation-toggle div {
    background-color: #4D4D4D;
}

.cmp-left-navigation--is-close .cmp-left-navigation-container{
    background-color: #4D4D4D;
}
.cmp-left-navigation--is-close .cmp-left-navigation-toggle div {
    background-color: #4D4D4D;
}
.cmp-left-navigation--is-close .cmp-left-navigation-item-parent.selected, .cmp-left-navigation--is-close .cmp-left-navigation-item-home.selected {
    background-color: #124D80;
    color:  hsl(0, 0%, 100%);
}

.cmp-left-navigation--is-open .cmp-left-navigation-item-parent.selected {
    background-color: #4D4D4D;
    color:  hsl(0, 0%, 100%);
}
.cmp-left-navigation--is-open .cmp-left-navigation-item-parent.selected .cmp-icon .cmp-icon__svg {
    --color-svg_fg:hsl(0, 0%, 100%);
}

.navigation-item-parent:hover, .cmp-left-navigation--is-open .cmp-left-navigation-item-home:hover {
    background-color: #124D80;
    color: hsl(0, 0%, 100%);
    outline: none;
}
.cmp-left-navigation--is-open .cmp-left-navigation-item-child:hover {
    background-color: #124D80;
    border-bottom: 2px solid #124D80;
    color: hsl(0, 0%, 100%);
    outline: none;
}

.cmp-left-navigation--is-open .cmp-left-navigation-item-parent:hover,.cmp-left-navigation--is-open .cmp-left-navigation-item-home:hover  {
    background-color: #124D80;
    color: hsl(0, 0%, 100%);
    outline: none;
}
.cmp-left-navigation--is-open .cmp-left-navigation-item-parent:hover .cmp-icon .cmp-icon__svg{
    --color-svg_fg:hsl(0, 0%, 100%);
} 
.cmp-left-navigation--is-close .cmp-left-navigation-item-parent.selected .cmp-left-navigation-item-parent-inner, .cmp-left-navigation--is-close .cmp-left-navigation-item-home.selected .cmp-left-navigation-item-parent-inner {
    border-left: none;
}
.cmp-left-navigation-item-child.selected {
    background-color:#124D80;
    color: hsl(0, 0%, 100%);
}

.cmp-icon__svg--blue{
    --color-svg_fg:  hsl(0, 0%, 100%);
}

.cmp-icon__svg--black  {
    --color-svg_fg:  hsl(0, 0%, 100%);
}
.cmp-left-navigation-item-parent {
    font-weight: 400;
}


