.ErrorPage{
    position: relative;
    height: 100vh;
}

.error_background{
    background: url("../../../Images/arrow-bg.png") no-repeat;
    height: 100vh;
    margin-top: -30px;
    margin-bottom: -100px;
  }

.content{
    width:600px;
    height:600px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top:50%;
    margin-left: -300px;
    margin-top: -300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content h1{
    font-style: normal;
    font-weight: 300;
    font-size: 150px;
    line-height: 80px;
    color: #000000;
}

.content h3{
    position: relative;
    font-weight: 100;
  /*  height: 105px; */
    font-size: 25px;
    margin-top: 50px;
    margin-bottom: 0;
}

.content p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 40px;
}


.alertContent{
    width:90%;
    margin: 0 auto;
    left: 20%;
    top: 6%;
    position: absolute;
    display: flex;
    align-items: center;
}
