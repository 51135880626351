.projection-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.projection-header h1 {
  margin: 0 !important;
  font: 48px metro-sans-light;
  font-weight: 500;
}

.projections-table thead th {
  font-weight: normal;
  border-left: 0px;
  border-right: 0px;
}

.projections-table tbody td {
  border-left: 0px;
  border-right: 0px;
  font-size: 12px;
}
.projections-table tbody tr:hover {
  background-color: #f7f7f7 !important;
  cursor: pointer;
}
.projections-column-header {
  text-align: left;
  background: #333333;
  color: #ffffff;
  width: 35%;
}
.projections-column-header-medium {
  text-align: left;
  background: #333333;
  color: #ffffff;
  width: 20%;
}
.projections-column-header-small {
  text-align: left;
  background: #333333;
  color: #ffffff;
  width: 15%;
}
.projections-column-header-action {
  text-align: left;
  background: #333333;
  color: #ffffff;
  width: 10%;
}
.projections-column {
  text-align: left;
  width: 35%;
}
.projections-column-medium {
  text-align: left;
  width: 20%;
}
.projections-column-small {
  text-align: left;
  width: 15%;
}
.projections-column-action {
  text-align: center;
  width: 10%;
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: #333333;
  color: #ffffff;
  font-weight: 700;
  border: 1px solid #333333;
  padding-top: 1px;
  padding-bottom: 1px;
}
.p-datatable .p-datatable-tbody > tr > td:not(.section-header td) {
  border: 1px solid #dee2e6 !important;
  padding: 0.2rem 0.5rem;
}
.p-datatable .p-datatable-tbody .section-header td {
  padding: 0.5rem 0.5rem !important;
  
}

.p-inputtext {
  width: 50px;
}
.section-table th,
.projections-table th {
  border: 0 !important;
  background-color: #333333;
  color: #ffffff;
  font-weight: 600;
  top: 0;
  position: sticky;
}

table.financial-statement th {
  text-align: center;
}

.section-table tbody td:not(:first-child) {
  text-align: right;
}

table.financial-statement input {
  text-align: right;
}

.accounting-wrapper {
  display: flex !important;
  justify-content: flex-end;
}
.accounting-wrapper span,
.section-table tbody td span {
  font-size: 12px;
}

.projection-financials-header h1 {
  margin: 0 !important;
}

table.financial-statement .form-control {
  font-size: 12px;
  padding: 0.2rem;
  height: 1.5rem;
}

.projections-edit {
  color: #3174d8;
}
.section-table table {
  width: 100%;
}
table.financial-statement th div.p-column-header-content {
  justify-content: center;
}
.section-table thead tr {
  text-align: left;
}

.section-table tbody {
  width: 100%;
}

.section-table tr {
  width: 100%;
}

.section-table tbody td {
  border-left: 0 !important;
  border-right: 0;
  text-align: left;
  font-size: 12px;
}
.section-table {
  margin-top: 3px;
}
.section-header {
  border-style: none none none none !important;
  background-color: #ebebeb !important;
  font-weight: 600;
  line-height: 16px;
  border-right: 0px;
}
.section-header td {
  border-right: none !important;
  font-weight: bold;
}
.section-alternate-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%;
  background-color: #ffffff;
  z-index: 100;
}
.section-group-name {
  text-align: left;
  border-right: 1px !important;
  border-right-style: solid !important;
  border-right-color: #333333 !important;
  width: 260px;
}
.section-group-value {
  text-align: center;
  border-right: 0;
  width: 160px;
}
.section-group-input {
  border-right-width: 1px;
  width: 150px;
}
.section-button {
  margin-top: 10px;
  margin-bottom: 10px;
}
.section-footer-button-toolbar {
  text-align: right;
  margin-right: 0.5rem;
}
.section-footer-button {
  padding: 2px;
  margin: 5px;
}
.p-datatable .p-datatable-footer {
  padding: 0.2rem 0.2rem !important;
}
.p-inputtext.p-inputtext-sm {
  width: 100%;
  margin: auto;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px !important;
}

.custom-datatable .p-datatable-thead > tr > th {
  background-color: #ffffff; 
  color: #333333;
}

th.actual-header {
  text-align: center !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-right:2px !important;
  width: 40%;
}

th.actual-header .p-column-header-content {
  border: 2px solid black !important;
  text-align: center !important;
}

th.margin-actual-header{
  text-align: center !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-right:2px !important;
  width: 28%;
}

th.margin-actual-header .p-column-header-content {
  border: 2px solid black !important;
  text-align: center !important;
}

th.projected-header {
  text-align: center !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  width: 40%;
}

th.projected-header .p-column-header-content {
  border: 2px solid black !important;
  text-align: center !important;
}

th.margin-projected-header {
  text-align: center !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  width: 38%;
}

th.margin-projected-header .p-column-header-content {
  border: 2px solid black !important;
  text-align: center !important;
}

.year-header{
  width: 40px;
  padding: 1px !important;
}

.custom-datatable > .p-datatable-tbody > tr.align-middle > td:first-child {
  white-space: nowrap;
}

.custom-datatable .p-datatable-tbody > tr > td:not(.section-header td) {
  border: 0 !important;
  padding: 0.2rem 0.5rem;
}
.custom-datatable > .p-datatable-tbody > tr.header-bold > td:first-child {
  font-weight: bold;
  padding-left: 20px !important;
}

.custom-datatable > .p-datatable-tbody > tr.marginHeader-bold > td:first-child {
  font-weight: bold;
  /* padding-left: 20px !important; */
}

/* .custom-datatable tbody td:not(:first-child) {
  text-align: center;
} */

/* .custom-datatable tbody td div.accounting-wrapper {
  justify-content: center !important;
} */

.projection-financials-header .app-title-h1{
  margin-left: 20px !important;
}
.dollar {
  /* float: left; */
  padding-right: 65px; 
}

.percent {
  /* float: right; */
  /* padding-left: 30px;  */
  padding-right: 30px; 
}

/* .dollar .percent{
  margin-right: 20px !important; 
} */
.accounting-wrapper .number-field {
  margin-right: 10px !important; /* Adjust the value as per your need */
}
