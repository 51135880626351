.report-section {
  color: white !important;
  font-size: 14px;
  height: 25px !important;
  max-height: 25px !important;
  background-color: #124D80 !important;
  font-weight: bold !important;
}

.p-treetable-toggler {
  width: 1rem !important;
  height: 1rem !important;
}

.p-treetable-toggler-icon {
  width: 10px !important;
  height: 10px !important;
}

.card {
  margin: auto;
  width: 100%;
}

.bd-table-cell {
    border: 0px solid #fff !important;
    width: 80px;
    font-size: 10px;
    height: 20px !important;
    max-height: 20px !important;
    padding: 0 !important;
    }
    th.bd-table-cell{
      height: 20px !important;
      text-align:right !important;
    }
    .p-treetable>.p-treetable-wrapper::-webkit-scrollbar {
      display: none !important;
    } 
    .p-treetable .p-treetable-thead > tr > th{
      padding: 0 !important;
      font-weight: bold !important;
    }
    .p-column-title{
      font-size: larger !important;
      padding: 0 30px !important;
    }
    .p-treetable .p-treetable-footer{
      font-weight: 600;
      font-size: 10px;
    }
    .p-treetable .p-treetable-tbody > tr > td {
      padding: 0;
    }

    .bd-group-total-row {
      /* border: 1px solid black !important; */
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      width: 115px;
      height: 20px !important;
      max-height: 20px !important;
      font-size: 12px;
      font-weight: bold !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
      text-indent: 20px; 
    }
     .report-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .report-container .cmp-button{
      padding: 0.3rem 0.6rem !important
    }
    .report-title{
      margin-top: 5px;
      padding-left: 20px;
      margin-bottom: 5px;
    }
    .report-custom-title {
      font-size: 0px;
  }
  .period-source-header{
    margin-left: 420px;
    margin-bottom: 0;
    color:black;
    font-weight: bold;
  }
  .split-header{
    display: flex;
    flex-direction: row;
  }

  .report-metadata-subheader{
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .report-custom-subtitle {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 5px;
  }

  .report-group-drilldown{
    cursor: pointer;
  }

  .report-total{
    font-weight: bold;
  }

.report-grandtotal {
  font-weight: bold;
  position: relative;
  /* border-top: 2px solid black; */
}

  .p-treetable-scrollable-header{
    height: 40px;
    line-height: 1;
  }


  .tableStyle{
    width: 90% !important;
  }

  .report-title-container {
    height: 90px; /* Adjust the height as needed */
    overflow: hidden; /* Hide any content that exceeds the container height */
}


.pdfIcon{
  font-size: 35px;
}

.pdfBtn{
  margin-right: 50px;
  padding: 0px !important;
}

.pdfBtnAccount{
  margin-right: 50px;
  padding: 0px !important;
  margin-right: 10px !important;
}
  

  

  

