.side-drawer { 
    height: 100%;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1000;
 }
 .side-drawer.open {
   transform: translateX(0);
 }

 .card-item{
   background: white;
   display: flex;
   height: 100vh;
 }

 .side-drawer-items{
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
 }

 .side-drawer-toggle{
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .side-drawer-toggle > div {
   width: 23px;
   height: 76px;
   display: flex;
   justify-content: center;
   align-items: center;
   clip-path: polygon(11% 17%, 100% 0%, 100% 100%, 11% 86%, 11% 54%);
   background-color: #4D4D4D;
 }

 .side-drawer.open .side-drawer-toggle-icon{
   transform: rotate(270deg);
}

.side-drawer .side-drawer-toggle-icon{
   transform: rotate(90deg);
}

/* used for naming side drawer title
.side-drawer-close{
   background: transparent;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 25px;
}

.side-drawer-close div{
   transform: rotate(270deg);
   white-space: nowrap;
   background:#4D4D4D;
   color: white;
   font-size: 14px;
   width: 900px;
   display: flex;
   justify-content: center;
   padding-top: 5px;
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 5px;
}
*/

.side-drawer .side-drawer-toggle svg.cmp-icon__svg {
   --color-svg_fg: white;
}

