.fixed_container {
    margin-top: -10px;
    position: relative;
    overflow: auto;
    height: 85vh;
}

.fixed_header {
    box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
    padding-bottom: 18px;
}

.fixed_content {
    padding-top: 10px;
}

.users_vert_centered {
    display: flex;
    height: 100% !important;
    align-items: center !important;
}
.search_box{
    margin-left: 50px;
}

.users_result {
    color: #7B7B7B;
    font-size: 14px;
}

.disable_scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}

.disable_scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.input_no_border {
    border: none !important;
}

.add_user_btn {
    width: 140px;
}

.hideButton{
    display: none;
}