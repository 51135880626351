.user_card_col {
    /* margin-bottom: 15px; */
}

.user_card {
    border: none !important;
}

.user_card:hover {
    filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 15%));
    cursor: pointer;
}

.user_card_badge {
    width: 57px;
    height: 19px;
    font-size: 12px;
    color: black !important;
    background-color: #C4F0FE !important;
    padding-top: 2px !important;
    padding-bottom: 3px !important;
}

.user_card_label {
    font-size: 12px !important;
    color: #929292 !important;
    margin-right: 7px !important;
}

.user_card_admin_label {
    display: flex;
    align-items: center;
    height: 100%;
}

.user_card_toggle_label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.user_card_heading {
    margin-top: 20px;
    margin-bottom: 0 !important;
    line-height: 1.5 !important;
    font-weight: 700 !important;
}

.user_card_subheading {
    font-size: 14px !important;
}

.margin_top_10 {
    margin-top: 10px;
}

.user_card_divider {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.user_card_footer {
    padding-top: 0.5rem !important;
}

.user_card_footer_btn {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #929292 !important;
    font-size: 12px !important;
    width: 100% !important;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
}

.user_card_footer_btn i{
    margin-right: .5rem;
}

.user_card_footer_btn:disabled{
    color: #ccc!important;
    opacity: .65 !important;
    border-color: #ccc!important;
    box-shadow: inset 0 0 0 1px #ccc,0 0 0 0 #ccc!important;
}

.user_card_footer_btn:not(:disabled):not(.disabled):hover {
    background: none !important;
    color: #3174D8 !important;
}