input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: left;
}
input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: left;
}

/* Unify Additional Styles*/
.width-0 {
  width: 0px;
}

.height-0 {
  height: 0px;
}

.d-i-b {
  display: inline-block;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-7 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.padding-right-0  {
  padding-right: 0px !important;
}

.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn-xs {
  height: 24px !important;
  line-height: 1.5 !important;
  padding: 2px 6px !important;
  font-size: 12px !important;
}


.btn-group-sm {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.modal-content {
  border-top: 1px solid red !important;
}

.modal-override-header {
  color: #333333;
  font-size: 1.3rem;
  font-weight: 600;
}

.modal-form-label {
  font-size: 1.25rem;
}

/*fix with proper unify implementation*/
.modal-close {
  margin-left:380px !important;
}
/* end  */

.table-header {
  margin-bottom: 5px;
}
.navigation-panel{
  padding: 20px;
}
.main-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 1200px){
  .container-md, .unify .container-sm, .unify .container {
    max-width: 24000px !important;
}
}



.main-container.error-page {
  margin-left: 0px !important;
  margin-right: 0px !important;
  max-width: initial !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}



.alert-error.container-md {
  z-index: 1;
  position: absolute !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App>nav .login-links li{
  margin: 0;
  border-right: 1px solid #f4f4f4;
  padding: 0 1rem;
  display: list-item;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
}

.App>nav .login-links li:last-of-type{
  border-right: 0;
  padding-right: 0;
}

.App>nav .login-links {
  display: inline-flex;
}

.App>nav .login-links a{
  display: inline-block;
  font-size: 14px;
  color: #F7F7F7;
}
.divider{
  height: 20px;
  border-radius: 2px;
  width: 2px;
  margin: 2px 10px;
  background: #E6E6E6;
  float: right;
}

/*From now on will use this class for any h1 element which is used as a page title so that it is consistent app wide*/
.app-title-h1{
  margin: 0 0 10px 0 !important;
  font: 48px metro-sans-light;
  font-size: 2.5rem !important;
  font-weight: 500;
}



