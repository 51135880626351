.peer-group-fiexed-header {
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 18px;
}

.peer-group-inner-container {
    padding-left: 15px;
}

.peergroup-right-panel {
    margin-left: 15px;
    margin-right: -15px;
}

.peergroup-search-results {
    padding-left: 50px;
}

.add-peergroup-button {
    position: absolute;
    top: -19px;
}

.peergroup-header {
    display: inline;
    margin-bottom: 0px !important;
}

.peergroup-search-modal.modal-dialog {
    max-width: 1200px !important;
}

.peergroup-search-modal .modal-description{
    position: relative;
    left: -26px;
    font-size: 18px;
}

.peergroup-search-modal .modal-description{
    padding-bottom: 29px;
}

.peergroup-search-modal label{
    font-weight: bolder !important;
    font-size: 16px !important;
}

#peer-group-search-table-search_wrapper, #peer-group-search-table-saved_wrapper{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 1000px;
}

#peer-group-search-table-search_filter, #peer-group-search-table-saved_filter {
    display: none;
}


.peer-group-inner-container .add-search {
    text-align: center;
    font-size: 24px;
    line-height: 1.1;
    display: table-cell;
    vertical-align: middle;
}

.peer-group-manage-table {
    min-height: 500px !important;
    display: table;
}

.peergroup-edit .peergroup-right-panel #peer-group-search-table-search, .peergroup-edit .peergroup-right-panel #peer-group-search-table-saved {
    margin-top: 0px !important;
}

#group-description {
    height: 165px;
}

.add-search {
    cursor: pointer;
}

.manage-peergroup-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75;
    margin-right: 0px;
}

.manage-peergroup-footer .cancel{
    margin-right: 10px;
}

.peergroup-right-panel .panel {
    margin-bottom: 0px;
}

.manage-peergroup-footer .right-buttons {
    margin-top: 7px;
    text-align: end;
}

.peer-group-manage .last-updated{
    padding-left: 40px;
    margin-left: 14px;
    border-left: solid 1px #9292922e;
}

.peer-group-card {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.peer-group-card .panel {
    margin-bottom: 10px;
}

.peer-group-card h5 {
    margin-bottom: 0px;
}

.peer-group-card h6 {
    margin-bottom: 0px;
}


.peer-group-card a {
    color: #3174d8 !important;
}