.backdrop{
    width: 100% !important;
    height: 100% !important;
    position: fixed;
    z-index: 150;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
}

.modal{
    position: fixed;
    left: 25%;
    margin: 0 auto;
    z-index: 1000;
    background-color: white;
    width: 700px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px solid black;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.report_modal_header{
    background-color: black;
    color: white;
    width: 100% !important;
    padding: 10px;
    height: 50px
}

.report_modal_body{
    width: 60%;
    justify-content: center;
    float: right;
    margin-top: 10px;
}

.report_sidepanel{
    border: 1px solid #ccc;
    height: calc(100% - 50px);
    width: 30%;
    padding-left: 1rem;
    padding-right: 0.2rem;
    background-color: rgb(226, 226, 226);
    float: left;
}

.report_sidepanel p, span{
    font-size: 0.9rem;
}

.report_sidepanel span{
    font-weight: bold;
}


.report_button {
   height: 40px;
   font-size: 0.9rem;
   width: 240px;
   margin-left: 2rem;
}
.report_dropdown_style{
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-bottom: 5px;
}
.report_list {
    font-size: 0.9rem;
    padding-right: 10px;
    max-height: 350px;
    overflow: scroll;
}

.report_label {
  margin-right: 100px;;
}

h4{
    margin-top: 0px !important;
    font-size: 1px;
}

.reverse_order_ckbox{
    margin-top: 10px;
}

.margin_analysis_modal_height{
    height: 50vh !important;
}

.modal_height{
    height: 74vh;
}
.footer{
    margin-left: 30px;
}

.tooltip {
    position: relative;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    font-size: small;
    width: 80%;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    z-index: 1;
    bottom:100%;
    left: 20%;
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }






